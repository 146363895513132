import { UserGeneratedAnswersEnum } from './types';

export const pagesToQuestions = {
    about: [
        UserGeneratedAnswersEnum.first_name,
        UserGeneratedAnswersEnum.middle_name,
        UserGeneratedAnswersEnum.last_name,
        UserGeneratedAnswersEnum.preferred_name,
        UserGeneratedAnswersEnum.prev_lname,
        UserGeneratedAnswersEnum.gender,
        UserGeneratedAnswersEnum.gender_iden,
        UserGeneratedAnswersEnum.birth_date,
        UserGeneratedAnswersEnum.ssn,
        UserGeneratedAnswersEnum.phone_home,
        UserGeneratedAnswersEnum.phone_mobile,
        UserGeneratedAnswersEnum.optin_sms_ind,
        UserGeneratedAnswersEnum.address_line_1,
        UserGeneratedAnswersEnum.address_line_2,
        UserGeneratedAnswersEnum.city,
        UserGeneratedAnswersEnum.state,
        UserGeneratedAnswersEnum.zip,
        UserGeneratedAnswersEnum.country,
        UserGeneratedAnswersEnum.mailing_addr_same_as_perm_addr,
        UserGeneratedAnswersEnum.mailing_address_line_1,
        UserGeneratedAnswersEnum.mailing_address_line_2,
        UserGeneratedAnswersEnum.mailing_city,
        UserGeneratedAnswersEnum.mailing_state,
        UserGeneratedAnswersEnum.mailing_zip,
        UserGeneratedAnswersEnum.mailing_country,
        UserGeneratedAnswersEnum.mailing_addr_effective_date,
        UserGeneratedAnswersEnum.citizenship_type,
        UserGeneratedAnswersEnum.birth_country,
        UserGeneratedAnswersEnum.birth_state,
        UserGeneratedAnswersEnum.citizen_country,
        UserGeneratedAnswersEnum.alien_registration_number,
        UserGeneratedAnswersEnum.us_study_radio,
        UserGeneratedAnswersEnum.us_residency_radio,
        UserGeneratedAnswersEnum.citizenship_visa_status,
        UserGeneratedAnswersEnum.intended_visa_type,
        UserGeneratedAnswersEnum.current_visa_number,
        UserGeneratedAnswersEnum.ethnicity,
        UserGeneratedAnswersEnum.race_american_indian,
        UserGeneratedAnswersEnum.race_asian,
        UserGeneratedAnswersEnum.race_black,
        UserGeneratedAnswersEnum.race_native_hawaiian,
        UserGeneratedAnswersEnum.race_white,
        UserGeneratedAnswersEnum.tribe_radio,
        UserGeneratedAnswersEnum.tribe_affiliation,
        UserGeneratedAnswersEnum.tribe_affiliation_other,
        UserGeneratedAnswersEnum.military_status,
        UserGeneratedAnswersEnum.military_branch,
        UserGeneratedAnswersEnum.military_status_specified,
        UserGeneratedAnswersEnum.military_tuition_assistance,
        UserGeneratedAnswersEnum.military_self_edu_benefits,
        UserGeneratedAnswersEnum.military_self_veterans_benefits,
        UserGeneratedAnswersEnum.military_dependent,
        UserGeneratedAnswersEnum.military_spouse_status,
        UserGeneratedAnswersEnum.military_spouse_benefits,
        UserGeneratedAnswersEnum.law_enforcement,
    ],
    education: [
        UserGeneratedAnswersEnum.program_type,
        UserGeneratedAnswersEnum.applied_major,
        UserGeneratedAnswersEnum.app_concentration,
        UserGeneratedAnswersEnum.ctl_int_coll_univ,
        UserGeneratedAnswersEnum.ctl_int_elm_school,
        UserGeneratedAnswersEnum.ctl_int_ind_priv,
        UserGeneratedAnswersEnum.ctl_int_sec_school,
        UserGeneratedAnswersEnum.ctl_int_sec_comm_coll,
        UserGeneratedAnswersEnum.ce_int_geotechnical,
        UserGeneratedAnswersEnum.ce_int_structural,
        UserGeneratedAnswersEnum.ce_int_transportation,
        UserGeneratedAnswersEnum.ce_int_water_resources,
        UserGeneratedAnswersEnum.ga_int_european_studies,
        UserGeneratedAnswersEnum.ga_int_global_studies,
        UserGeneratedAnswersEnum.ga_int_la_studies,
        UserGeneratedAnswersEnum.ga_int_asian_studies,
        UserGeneratedAnswersEnum.ga_int_me_studies,
        UserGeneratedAnswersEnum.hw_int_water_man,
        UserGeneratedAnswersEnum.hw_int_water_qual,
        UserGeneratedAnswersEnum.hw_int_water_quan,
        UserGeneratedAnswersEnum.li_int_data,
        UserGeneratedAnswersEnum.li_int_info_tech,
        UserGeneratedAnswersEnum.li_int_pub,
        UserGeneratedAnswersEnum.li_int_school,
        UserGeneratedAnswersEnum.li_int_special,
        UserGeneratedAnswersEnum.li_int_arc_pres,
        UserGeneratedAnswersEnum.li_int_youth_serv,
        UserGeneratedAnswersEnum.se_int_appl_ba,
        UserGeneratedAnswersEnum.se_int_fund_se,
        UserGeneratedAnswersEnum.se_int_sec_trans,
        UserGeneratedAnswersEnum.cs_int,
        UserGeneratedAnswersEnum.geog_env_int,
        UserGeneratedAnswersEnum.ngem_int_engineering,
        UserGeneratedAnswersEnum.ngem_int_management,
        UserGeneratedAnswersEnum.entry_term,
        UserGeneratedAnswersEnum.parent_education,
        UserGeneratedAnswersEnum.parent_2_education,
        UserGeneratedAnswersEnum.prev_attend,
        UserGeneratedAnswersEnum.prev_attend_from_month,
        UserGeneratedAnswersEnum.prev_attend_to_month,
        UserGeneratedAnswersEnum.prev_attend_from_year,
        UserGeneratedAnswersEnum.prev_attend_to_year,
        UserGeneratedAnswersEnum.sooner_id,
        UserGeneratedAnswersEnum.college_degree,
        UserGeneratedAnswersEnum.college_gpa,
        UserGeneratedAnswersEnum.college_credits,
        UserGeneratedAnswersEnum.college_attend_from_month,
        UserGeneratedAnswersEnum.college_attend_to_month,
        UserGeneratedAnswersEnum.college_attend_from_year,
        UserGeneratedAnswersEnum.college_attend_to_year,
        UserGeneratedAnswersEnum.college_2_degree,
        UserGeneratedAnswersEnum.college_2_gpa,
        UserGeneratedAnswersEnum.college_2_credits,
        UserGeneratedAnswersEnum.college_2_attend_from_month,
        UserGeneratedAnswersEnum.college_2_attend_to_month,
        UserGeneratedAnswersEnum.college_2_attend_from_year,
        UserGeneratedAnswersEnum.college_2_attend_to_year,
        UserGeneratedAnswersEnum.college_3_degree,
        UserGeneratedAnswersEnum.college_3_gpa,
        UserGeneratedAnswersEnum.college_3_credits,
        UserGeneratedAnswersEnum.college_3_attend_from_month,
        UserGeneratedAnswersEnum.college_3_attend_to_month,
        UserGeneratedAnswersEnum.college_3_attend_from_year,
        UserGeneratedAnswersEnum.college_3_attend_to_year,
        UserGeneratedAnswersEnum.college_4_degree,
        UserGeneratedAnswersEnum.college_4_gpa,
        UserGeneratedAnswersEnum.college_4_credits,
        UserGeneratedAnswersEnum.college_4_attend_from_month,
        UserGeneratedAnswersEnum.college_4_attend_to_month,
        UserGeneratedAnswersEnum.college_4_attend_from_year,
        UserGeneratedAnswersEnum.college_4_attend_to_year,
        UserGeneratedAnswersEnum.college_5_degree,
        UserGeneratedAnswersEnum.college_5_gpa,
        UserGeneratedAnswersEnum.college_5_credits,
        UserGeneratedAnswersEnum.college_5_attend_from_month,
        UserGeneratedAnswersEnum.college_5_attend_to_month,
        UserGeneratedAnswersEnum.college_5_attend_from_year,
        UserGeneratedAnswersEnum.college_5_attend_to_year,
        UserGeneratedAnswersEnum.college_name,
        UserGeneratedAnswersEnum.college_address_line_1,
        UserGeneratedAnswersEnum.college_city,
        UserGeneratedAnswersEnum.college_state,
        UserGeneratedAnswersEnum.college_zip,
        UserGeneratedAnswersEnum.college_ceeb,
        UserGeneratedAnswersEnum.college_2_name,
        UserGeneratedAnswersEnum.college_2_address_line_1,
        UserGeneratedAnswersEnum.college_2_city,
        UserGeneratedAnswersEnum.college_2_state,
        UserGeneratedAnswersEnum.college_2_zip,
        UserGeneratedAnswersEnum.college_2_ceeb,
        UserGeneratedAnswersEnum.college_3_name,
        UserGeneratedAnswersEnum.college_3_address_line_1,
        UserGeneratedAnswersEnum.college_3_city,
        UserGeneratedAnswersEnum.college_3_state,
        UserGeneratedAnswersEnum.college_3_zip,
        UserGeneratedAnswersEnum.college_3_ceeb,
        UserGeneratedAnswersEnum.college_4_name,
        UserGeneratedAnswersEnum.college_4_address_line_1,
        UserGeneratedAnswersEnum.college_4_city,
        UserGeneratedAnswersEnum.college_4_state,
        UserGeneratedAnswersEnum.college_4_zip,
        UserGeneratedAnswersEnum.college_4_ceeb,
        UserGeneratedAnswersEnum.college_5_name,
        UserGeneratedAnswersEnum.college_5_address_line_1,
        UserGeneratedAnswersEnum.college_5_city,
        UserGeneratedAnswersEnum.college_5_state,
        UserGeneratedAnswersEnum.college_5_zip,
        UserGeneratedAnswersEnum.college_5_ceeb,
        UserGeneratedAnswersEnum.gmat_taken,
        UserGeneratedAnswersEnum.gmat_taken_month,
        UserGeneratedAnswersEnum.gmat_taken_year,
        UserGeneratedAnswersEnum.gmat_score_composite,
        UserGeneratedAnswersEnum.gmat_score_composite_percentile,
        UserGeneratedAnswersEnum.gmat_score_quant,
        UserGeneratedAnswersEnum.gmat_score_quant_percentile,
        UserGeneratedAnswersEnum.gmat_score_verbal,
        UserGeneratedAnswersEnum.gmat_score_verbal_percentile,
        UserGeneratedAnswersEnum.gmat_score_writing,
        UserGeneratedAnswersEnum.gmat_score_writing_percentile,
        UserGeneratedAnswersEnum.gmat_plan_month,
        UserGeneratedAnswersEnum.gmat_plan_year,
        UserGeneratedAnswersEnum.gmat_score_reasoning,
        UserGeneratedAnswersEnum.gmat_score_reasoning_percentile,
        UserGeneratedAnswersEnum.gre_taken,
        UserGeneratedAnswersEnum.gre_taken_month,
        UserGeneratedAnswersEnum.gre_plan_year,
        UserGeneratedAnswersEnum.gre_score_verbal,
        UserGeneratedAnswersEnum.gre_score_verbal_percentile,
        UserGeneratedAnswersEnum.gre_score_quant,
        UserGeneratedAnswersEnum.gre_score_quant_percentile,
        UserGeneratedAnswersEnum.gre_score_writing,
        UserGeneratedAnswersEnum.gre_score_writing_percentile,
        UserGeneratedAnswersEnum.gre_plan_month,
        UserGeneratedAnswersEnum.gre_plan_year,
        UserGeneratedAnswersEnum.toefl_taken,
        UserGeneratedAnswersEnum.toefl_taken_month,
        UserGeneratedAnswersEnum.toefl_taken_year,
        UserGeneratedAnswersEnum.toefl_score,
        UserGeneratedAnswersEnum.toefl_score_listening,
        UserGeneratedAnswersEnum.toefl_score_speaking,
        UserGeneratedAnswersEnum.toefl_score_reading,
        UserGeneratedAnswersEnum.toefl_score_writing,
        UserGeneratedAnswersEnum.toefl_plan_month,
        UserGeneratedAnswersEnum.toefl_plan_year,
        UserGeneratedAnswersEnum.ielts_taken,
        UserGeneratedAnswersEnum.ielts_taken_month,
        UserGeneratedAnswersEnum.ielts_taken_year,
        UserGeneratedAnswersEnum.ielts_score_overall,
        UserGeneratedAnswersEnum.ielts_score_listening,
        UserGeneratedAnswersEnum.ielts_score_reading,
        UserGeneratedAnswersEnum.ielts_score_writing,
        UserGeneratedAnswersEnum.ielts_score_speaking,
        UserGeneratedAnswersEnum.ielts_plan_month,
        UserGeneratedAnswersEnum.ielts_plan_year,
        UserGeneratedAnswersEnum.college_transcript,
        UserGeneratedAnswersEnum.identification,
    ],
    'final-steps': [
        UserGeneratedAnswersEnum.conviction,
        UserGeneratedAnswersEnum.conviction_month,
        UserGeneratedAnswersEnum.conviction_year,
        UserGeneratedAnswersEnum.conviction_comment,
        UserGeneratedAnswersEnum.suspended,
        UserGeneratedAnswersEnum.suspended_month,
        UserGeneratedAnswersEnum.suspended_year,
        UserGeneratedAnswersEnum.suspended_comment,
        UserGeneratedAnswersEnum.protective,
        UserGeneratedAnswersEnum.protective_month,
        UserGeneratedAnswersEnum.protective_year,
        UserGeneratedAnswersEnum.protective_comment,
        UserGeneratedAnswersEnum.prohibited,
        UserGeneratedAnswersEnum.prohibited_month,
        UserGeneratedAnswersEnum.prohibited_year,
        UserGeneratedAnswersEnum.prohibited_comment,
        UserGeneratedAnswersEnum.discharge,
        UserGeneratedAnswersEnum.discharge_month,
        UserGeneratedAnswersEnum.discharge_year,
        UserGeneratedAnswersEnum.discharge_comment,
        UserGeneratedAnswersEnum.legal_agree,
    ],
};
